@use '@angular/material' as mat;
@import '../../../styles/utils/fill';

@mixin dsh-button-toggle-theme($theme) {
    $primary: map-get($theme, primary);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);
    $divider-color: mat.get-color-from-palette($foreground, divider, 0.12);

    .dsh-button-toggle {
        background: mat.get-color-from-palette($background, card);
        @include fill(mat.get-color-from-palette($foreground, text));

        &-group .dsh-button-toggle + .dsh-button-toggle {
            border-left: solid 1px $divider-color;
        }

        [dir='rtl'] .dsh-button-toggle-group .dsh-button-toggle + .dsh-button-toggle {
            border-left: none;
            border-right: solid 1px $divider-color;
        }

        &-group.dsh-button-toggle-vertical {
            .dsh-button-toggle + .dsh-button-toggle {
                border-left: none;
                border-right: none;
                border-top: solid 1px $divider-color;
            }
        }

        &-checked {
            &.dsh-button-toggle {
                @include fill(mat.get-color-from-palette($primary, default));
            }
        }

        &-disabled {
            background-color: mat.get-color-from-palette($background, disabled-button-toggle);
            @include fill(mat.get-color-from-palette($foreground, disabled-button));

            &.dsh-button-toggle {
                background: mat.get-color-from-palette($background, card);
            }

            &.dsh-button-toggle-checked {
                background-color: mat.get-color-from-palette($background, selected-disabled-button);
            }
        }

        &-standalone.dsh-button-toggle,
        &-group {
            border: solid 1px $divider-color;
        }
    }
}

@mixin dsh-button-toggle-typography($config) {
    .dsh {
        &-button-toggle-standalone,
        &-button-toggle-group {
            font: {
                family: mat.font-family($config, button);
                size: mat.font-size($config, button);
                weight: mat.font-weight($config, button);
            }
        }
    }
}
