@use '@angular/material' as mat;

@mixin dsh-status-theme($theme) {
    $foreground: map-get($theme, foreground);

    $text: map-get($foreground, text);

    $success-base: map-get($theme, success-base);
    $pending-base: map-get($theme, pending-base);
    $warn-base: map-get($theme, warn-base);

    .dsh-status {
        color: $text;

        .dsh-status-icon {
            background-color: $text;
        }

        &-success {
            color: $success-base;

            .dsh-status-icon {
                background-color: $success-base;
            }
        }

        &-pending {
            color: $pending-base;

            .dsh-status-icon {
                background-color: $pending-base;
            }
        }

        &-warn {
            color: $warn-base;

            .dsh-status-icon {
                background-color: $warn-base;
            }
        }
    }
}

@mixin dsh-status-typography($config) {
    .dsh-status-label {
        @include mat.typography-level($config, body-1);
    }
}
