@use '@angular/material' as mat;
@import '../../../styles/utils/fill';

@mixin dsh-state-nav-theme($theme) {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    $primary: map-get($theme, primary);
    $primary-text: map-get($primary, contrast);
    $accent: map-get($theme, accent);
    $accent-text: map-get($accent, contrast);
    $warn: map-get($theme, warn);
    $warn-text: map-get($warn, contrast);

    .dsh-state-nav {
        &-item {
            color: map-get($foreground, text);

            // The class order directly affects the order of applying styles (for example, both .warn and .selected)
            &.success {
                mat-icon {
                    @include fill(mat.get-color-from-palette($accent, default));
                }
            }
            &.warn {
                mat-icon {
                    @include fill(mat.get-color-from-palette($warn, 300));
                }
            }
            &.selected {
                color: mat.get-color-from-palette($primary, default);
            }
        }

        &-flat {
            .dsh-state-nav-item {
                &:hover {
                    background: map-get($background, hover);
                }

                // The class order directly affects the order of applying styles (for example, both .warn and .selected)
                &.success {
                    background: mat.get-color-from-palette($accent, default);
                    color: map-get($accent-text, 300);
                }
                &.warn {
                    background: mat.get-color-from-palette($warn, 300);
                    color: map-get($warn-text, 300);
                }
                &.selected {
                    background: mat.get-color-from-palette($primary, default);
                    color: map-get($primary-text, 400);
                }
            }
        }
    }
}

@mixin dsh-state-nav-typography($config) {
    .dsh-state-nav {
        &-item {
            @include mat.typography-level($config, subheading-2);
        }
    }
}
