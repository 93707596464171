@use '@angular/material' as mat;

@mixin dsh-balances-theme($theme) {
    $foreground: map-get($theme, foreground);

    .dsh-balances {
        &-title {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}

@mixin dsh-balances-typography($config) {
    .dsh-balances {
        &-title {
            @include mat.typography-level($config, caption);
        }
        &-balance-lg {
            @include mat.typography-level($config, title);
        }
        &-balance-md {
            @include mat.typography-level($config, subheading-2);
        }
    }
}
