@use '@angular/material' as mat;

@mixin dsh-no-shops-alert-theme($theme) {
    $primary: map-get($theme, primary);

    .dsh-no-shops-action {
        color: mat.get-color-from-palette($primary, default);
    }
}

@mixin dsh-no-shops-alert-typography($config) {
    .dsh-no-shops-content {
        @include mat.typography-level($config, subheading-2);
    }
}
