@use '@angular/material' as mat;

@mixin mat-tabs-override() {
    .mat-tab-header-pagination {
        box-shadow: none !important;
    }

    .mat-tab-links {
        .mat-tab-link {
            padding: 0;
            min-width: 0;
            opacity: 1;
            margin-right: 24px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@mixin mat-tabs-override-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .mat {
        &-tab-links {
            border-color: map-get($foreground, divider);
        }

        &-tab-label {
            color: map-get($foreground, text);

            &-active {
                color: mat.get-color-from-palette($primary, default);
            }

            &-disabled {
                color: map-get($foreground, disabled-text);
            }
        }
    }

    .mat-tab-nav-bar {
        border-color: transparent !important;
    }
}

@mixin mat-tabs-override-typography($config) {
    .mat-tab-links {
        .mat-tab-link {
            @include mat.typography-level($config, subheading-1);
        }
    }

    .dsh-top-tab-nav-bar {
        .mat-tab-links {
            .mat-tab-link {
                @include mat.typography-level($config, subheading-2);
            }
        }
    }
}
