@use '@angular/material' as mat;

@mixin dsh-details-item-theme($theme) {
    $foreground: map-get($theme, foreground);

    .dsh-details-item {
        &-title {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}

@mixin dsh-details-item-typography($config) {
    .dsh-details-item {
        &-title {
            @include mat.typography-level($config, caption);
        }

        &-content {
            @include mat.typography-level($config, body-1);
        }
    }
}
