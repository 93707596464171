@use '@angular/material' as mat;

@mixin panel($color) {
    border: solid 1px $color;
    .dsh-panel-header {
        background: $color;
    }
}

@mixin dsh-panel-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .dsh-panel {
        @include panel(map-get($background, card));
        &-primary {
            @include panel(mat.get-color-from-palette($primary, 50));
        }
        &-accent {
            @include panel(mat.get-color-from-palette($accent, 50));
        }
    }
}

@mixin dsh-panel-typography($config) {
    .dsh-panel-header-content {
        font: {
            family: mat.font-family($config, body-1);
            size: mat.font-size($config, body-1);
            weight: mat.font-weight($config, body-1);
        }
    }
}
