@use '@angular/material' as mat;
@import './timeline-item/timeline-item-badge/timeline-item-badge-theme';

@mixin dsh-timeline-theme($theme) {
    $foreground: map-get($theme, foreground);
    $success-base: map-get($theme, success-base);
    $pending-base: map-get($theme, pending-base);
    $warn-base: map-get($theme, warn-base);
    $line-color: map-get($foreground, border);

    .dsh-timeline {
        border-bottom-color: $line-color;

        &::after {
            background-color: $line-color;
        }
    }

    .dsh-timeline-item-badge {
        background-color: $line-color;
    }

    @include dsh-timeline-item-badge-theme($theme);
}

@mixin dsh-timeline-typography($config) {
    .dsh-timeline {
        @include mat.typography-level($config, body-1);
    }
}
