@use '@angular/material' as mat;

@mixin dsh-row-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);

    .dsh-row {
        background-color: mat.get-color-from-palette($background, card);
        &.dsh-primary {
            background-color: mat.get-color-from-palette($primary, 50);
        }

        &-header-label {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}

@mixin dsh-row-typography($config) {
    .dsh-row {
        &-header-label {
            @include mat.typography-level($config, body-2);
        }
        &-label {
            @include mat.typography-level($config, body-1);
        }
    }
}
