@use '@angular/material' as mat;

@mixin dsh-link-theme($theme) {
    .dsh-link {
        color: map-get($theme, primary, default);
    }
}

@mixin dsh-link-typography($config) {
    .dsh-link {
        @include mat.typography-level($config, body-1);
    }
}
