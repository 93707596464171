@use '@angular/material' as mat;

@mixin dsh-navbar-item-theme($theme) {
    $primary: map-get($theme, primary);

    .dsh-navbar-item-active {
        background: mat.get-color-from-palette($primary, default, 0.12);
    }
}

@mixin dsh-navbar-item-typography($config) {
    .dsh-navbar-item-content {
        @include mat.typography-level($config, subheading-2);
    }
}
