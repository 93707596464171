@use '@angular/material' as mat;
@import '../../../styles/utils/shadow';

@mixin dsh-card-theme($theme) {
    $background: map-get($theme, background);

    .dsh-card {
        @include dsh-shadow($theme);
        background-color: mat.get-color-from-palette($background, card);
    }
}

@mixin dsh-card-typography($config) {
    .dsh-card {
        font-family: mat.font-family($config);
    }

    .dsh-card-header .dsh-card-title {
        font-size: mat.font-size($config, title);
    }

    .dsh-card-content {
        font-size: mat.font-size($config, body-1);
    }
}
