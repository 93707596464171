@use '@angular/material' as mat;

@mixin dsh-breadcrumb-theme($theme) {
    $foreground: map-get($theme, foreground);

    .dsh-breadcrumb {
        color: map-get($foreground, secondary-text);
    }
}

@mixin dsh-breadcrumb-typography($config) {
    .dsh-breadcrumb {
        @include mat.typography-level($config, subheading-2);
    }
}
