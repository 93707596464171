@mixin mat-dialog-override() {
    .mat-dialog-container {
        box-shadow: none !important;
        padding: 24px !important;
        border-radius: 8px !important;

        // Clarify padding-bottom in overflow content
        & > *:last-child:after {
            display: block;
            content: '';
            height: 1rem;
            margin-bottom: -1rem;
        }
    }
}
