@use '@angular/material' as mat;

@mixin dsh-last-updated-theme($theme) {
    $foreground: map-get($theme, foreground);

    .dsh-last-updated {
        color: mat.get-color-from-palette($foreground, secondary-text);
    }
}

@mixin dsh-last-updated-typography($config) {
    .dsh-last-updated-update-link {
        text-decoration: underline;
    }

    .dsh-last-updated {
        @include mat.typography-level($config, caption);
    }
}
